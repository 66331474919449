import { http } from "@/composable/httpService";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { defineStore } from "pinia";
import { computed } from "vue";
import { ref } from "vue";

export const useReportSummaryStore = defineStore("reportSummaryStore", () => {
  const summary = ref();
  const loadingSummary = ref(false);

  const reportSummaryCards = computed(() => {
    if (!summary.value) return [];
    return [
      {
        title: "Courses Completed",
        key: "courses_completed_count",
        value: summary.value.courses_completed_count,
      },
      {
        title: "Certificates Earned",
        key: "total_certificates_count",
        value: summary.value.total_certificates_count,
      },
      {
        title: "Courses in Progress",
        key: "courses_in_progress_count",
        value: summary.value.courses_in_progress_count,
      },
      {
        title: "Assigned to You",
        key: "total_assigned_count",
        value: summary.value.total_assigned_count,
      },
    ];
  });

  const handleFetchReportSummary = async () => {
    try {
      loadingSummary.value = true;
      const response = await http().get("/v3/user/my-progress");
      summary.value = response.data;
    } catch (error) {
      useHandleErrorStatus(error);
    } finally {
      loadingSummary.value = false;
    }
  };

  return {
    handleFetchReportSummary,
    summary,
    loadingSummary,
    reportSummaryCards,
  };
});
