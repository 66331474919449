<template>
  <div class="flex justify-center items-center w-fit max-w-fit gap-2">
    <ViewListIcon
      @click="listType = 'list'"
      :class="[
        'w-10 p-2 border border-untitled-gray-200 hover:bg-untitled-gray-100 cursor-pointer rounded-md',
        {
          'text-white bg-untitled-gray-400 hover:text-untitled-gray-600':
            listType === 'list',
          'bg-white': listType !== 'list',
        },
      ]"
    />
    <ViewGridIcon
      @click="listType = 'grid'"
      :class="[
        'w-10 p-2 border border-untitled-gray-200 hover:bg-untitled-gray-100 cursor-pointer rounded-md',
        {
          'text-white bg-untitled-gray-400  hover:text-untitled-gray-600':
            listType === 'grid',
          'bg-white': listType !== 'grid',
        },
      ]"
    />
  </div>
</template>

<script setup>
import { useHomeTabsStore } from "@/stores/home/useHomeTabs.store";
import { storeToRefs } from "pinia";

const homeTabsStore = useHomeTabsStore();

import { ViewListIcon, ViewGridIcon } from "@heroicons/vue/solid";
const { listType } = storeToRefs(homeTabsStore);
</script>

<style lang="scss" scoped></style>
