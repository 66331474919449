<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-[100]">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="">
                <div class="mt-3 text-center sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900"
                    >Send as email</DialogTitle
                  >
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Enter email addresses, separated by commas.
                    </p>

                    <input
                      id="emails"
                      name="emails"
                      v-model="emails"
                      type="text"
                      placeholder="example@domain.com, another@domain.com"
                      class="block w-full p-5 appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm"
                    />
                    <div v-if="errorValue">
                      <ErrorSpan v-for="error in errorValue" :key="error">
                        {{ error }}
                      </ErrorSpan>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
                  :disabled="isLoading"
                  @click="handleClickCancel"
                  ref="cancelButtonRef"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 mr-3"
                  :disabled="isLoading"
                  @click="handleClickSend"
                >
                  <LoadingSpinner :show="isLoading" />
                  Send
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, watch } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import ErrorSpan from "../utilities/ErrorSpan.vue";
import LoadingSpinner from "../loaders/LoadingSpinner.vue";

const props = defineProps({
  open: {
    default: false,
    type: Boolean,
  },
  error: {
    type: Array,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["cancelEvent", "sendEvent"]);

const open = computed(() => props.open);
const isLoading = computed(() => props.loading);

const errorValue = computed(() => errorData.value || props.error);
const errorData = ref(null);
const emails = ref("");
const emailArray = computed(() => {
  return emails.value
    .split(",")
    .map((email) => email.trim())
    .filter(Boolean);
});

const handleClickCancel = () => {
  resetInput();
  emit("cancelEvent");
};

const handleClickSend = async () => {
  errorData.value = null;
  if (emailArray.value.length == 0) {
    errorData.value = ["The email field is requred."];
    return;
  }
  emit("sendEvent", emailArray.value);
};

const resetInput = () => {
  emails.value = "";
  errorData.value = null;
};

watch(open, () => {
  if (open.value) {
    resetInput();
  }
});
</script>
