import { http } from "@/composable/httpService";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { defineStore } from "pinia";
import { computed } from "vue";
import { ref } from "vue";

export const useTeamProgressStore = defineStore("teamProgressStore", () => {
  const loadingProgress = ref(false);
  const assignedSummary = ref();
  const isErrorTeamProgress = ref(false);

  const progress = computed(() => {
    if (!assignedSummary.value) return [];
    return [
      {
        title: "Completed Assignments",
        key: "totalCompleted",
        value: assignedSummary.value.totalCompleted,
        description:
          "This number represents the total number of completed assignments for your team.",
      },
      {
        title: "In Progress Assignments",
        key: "totalInProgress",
        value: assignedSummary.value.totalInProgress,
        description:
          "This number represents the total number of in-progress assignments for your team.",
      },
      {
        title: "Past Due Assignments",
        key: "totalOverdue",
        value: assignedSummary.value.totalOverdue,
        description:
          "This number represents the total number of past-due assignments for your team.",
      },
    ];
  });

  const handleFetchAssignedSummary = async () => {
    try {
      isErrorTeamProgress.value = false;
      loadingProgress.value = true;
      const { data } = await http().get(
        "/v3/reporting/assigned-histories/team-progress"
      );
      assignedSummary.value = data;
    } catch (error) {
      isErrorTeamProgress.value = true;
      useHandleErrorStatus(error);
    } finally {
      loadingProgress.value = false;
    }
  };

  return {
    loadingProgress,
    progress,
    assignedSummary,
    isErrorTeamProgress,
    handleFetchAssignedSummary,
  };
});
