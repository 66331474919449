<script setup>
  import { ref, onMounted } from 'vue';
  import DeferredContent from "@/components/utilities/DeferredContent.vue";
  import CompletedContentModal from "@/components/home/my-progress/CompletedContentModal.vue";
  import { useCompletedContentStore } from "@/stores/home/useCompletedContent.store";
  import { storeToRefs } from "pinia";
  import LoadingWrapper from "@/components/utilities/LoadingWrapper.vue";

  const completedContentStore = useCompletedContentStore();

  const showCompletedContentModal = ref(false);
  const { loadingContentCard, totalCount } = storeToRefs(completedContentStore);

  const handleClickCard = () => {
    showCompletedContentModal.value = true;
  };

  onMounted(() => {
    completedContentStore.handleFetchTotalCount();
  });
</script>

<template>
  <DeferredContent>
    <div
      class="flex-1 bg-untitled-gray-100 animate-pulse rounded-md h-32 relative"
      v-if="loadingContentCard"
    >
      <LoadingWrapper />
    </div>
    <div class="w-full mt-4"
       v-else
    >
      <div
        class="w-full flex flex-col gap-2 px-4 justify-center items-center h-32 bg-untitled-gray-100 hover:bg-untitled-gray-200 cursor-pointer rounded-md shadow-lg"
        @click="handleClickCard"
      >
        <p class="text-4xl font-semibold text-untitled-gray-600">{{ totalCount }}</p>
        <p class="text-xs text-untitled-gray-600 text-center font-medium">
          Completed Contents
        </p>
      </div>
    </div>
    <CompletedContentModal
      v-if="showCompletedContentModal"
      v-model="showCompletedContentModal"
    />
  </DeferredContent>
</template>

<style scoped lang="scss">

</style>
