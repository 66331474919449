<script setup>
import { ref, computed, defineProps, defineEmits, onMounted, watch } from "vue";
import GlobalDialog from "@/components/layout/GlobalDialog.vue";
import ECombobox from "@/components/element-components/ECombobox";
import ExportButton from "@/components/new-home/summary/components/ExportButton.vue";
import { useCompletedContentStore } from "@/stores/home/useCompletedContent.store";
import { storeToRefs } from "pinia";
import LoadingState from "@/components/partials/LoadingState.vue";
import { VButton } from "revdojo-vue-components";
import { useRouter } from "vue-router";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
    required: true,
  },
});

const router = useRouter();
const emit = defineEmits(["update:modelValue"]);
const openTab = ref(1);
const sortBy = ref("newest");

const completedContentStore = useCompletedContentStore();
const {
  units, courses, modules, playlists, scorm, loadingContent
} = storeToRefs(completedContentStore);

const showDialog = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit("update:modelValue", newValue);
  },
});

const sorting = (contents, sortValue) => {

  if (!contents || contents.length === 0) return;

  const sortData = [...contents];

  sortData.sort((a, b) => {
    switch (sortValue) {
      case "a-z":
        if (openTab.value === 1 || openTab.value === 5) {
          return a.unit?.name.localeCompare(b.unit?.name);
        }
        return a.name?.localeCompare(b.name);
      case "z-a":
        if (openTab.value === 1 || openTab.value === 5) {
          return b.unit?.name.localeCompare(a.unit?.name);
        }
        return b.name?.localeCompare(a.name);
      case "newest":
        return new Date(b.completed_at) - new Date(a.completed_at);
      case "oldest":
        return new Date(a.completed_at) - new Date(b.completed_at);
      case "recent-completion":
        return new Date(b.completed_at) - new Date(a.completed_at);
      case "oldest-completion":
        return new Date(a.completed_at) - new Date(b.completed_at);
    }
  });

  switch (openTab.value) {
    case 1:
      completedContentStore.updateUnits(sortData);
      break;
    case 2:
      completedContentStore.updateModules(sortData);
      break;
    case 3:
      completedContentStore.updateCourses(sortData);
      break;
    case 4:
      completedContentStore.updatePlaylists(sortData);
      break;
    case 5:
      completedContentStore.updateScorm(sortData);
      break;
  }
};

const sorts = ref([
  {
    name: "A-Z",
    value: "a-z",
  },
  {
    name: "Z-A",
    value: "z-a",
  },
  {
    name: "Newest to Oldest",
    value: "newest",
  },
  {
    name: "Oldest to Newest",
    value: "oldest",
  },
]);

const filteredUnitsList = computed(() => {
  return units.value ?? [];
});

const filteredModuleList = computed(() => {
  return modules.value ?? [];
});

const filteredCourseList = computed(() => {
  return courses.value ?? [];
});

const filteredPlaylist = computed(() => {
  return playlists.value ?? [];
});

const filteredScormList = computed(() => {
  return scorm.value ?? [];
});

const toggles = computed(() => {
  return [
    { id: 1, name: "Units", count: filteredUnitsList.value.length },
    { id: 2, name: "Modules", count: filteredModuleList.value.length },
    { id: 3, name: "Courses", count: filteredCourseList.value.length },
    { id: 4, name: "Playlist", count: filteredPlaylist.value.length },
    { id: 5, name: "Pryor", count: filteredScormList.value.length },
  ];
});

function changeSort(sortValue) {

  if (!sortValue) return;

  console.log('open tab: ', openTab.value);

  switch (openTab.value) {
    case 1:
      sorting(filteredUnitsList.value, sortValue);
      break;
    case 2:
      sorting(filteredModuleList.value, sortValue);
      break;
    case 3:
      sorting(filteredCourseList.value, sortValue);
      break;
    case 4:
      sorting(filteredPlaylist.value, sortValue);
      break;
    case 5:
      sorting(filteredScormList.value, sortValue);
      break;
  }
}

const handleToggleTabs = (tab) => {
  openTab.value = tab;
};

function goToUnit(unitId, playlistId = null, playlistType = "") {
  if (playlistId) {
    if (playlistType === "custom") {
      router.push(`/custom-playlist/${unitId}/${playlistId}`);
    } else {
      router.push(`/units/${unitId}/${playlistId}`);
    }
    return;
  }

  router.push(`/units/${unitId}`);
}

function goToUnitScorm(unit) {
  if (unit.type === "scorm") {
    return router.push({
      name: "ScormCourseWatch",
      params: {
        id: unit.scorm_provider_id,
      },
    });
  }

  router.push(`/units/${unit.id}`);
}

onMounted(() => {
  completedContentStore.handleFetchCompletedContent();
});

watch(sortBy, (val) => {
  changeSort(val);
});

watch(playlists, (newValue) => {
  console.log("Playlists changed:", newValue);
}, { deep: true });

watch(openTab, () => {
  changeSort(sortBy.value)
});

</script>

<template>
  <GlobalDialog
    :titleCenter="false"
    dialogDefaultSize="40%"
    v-model="showDialog"
    title="Completed Contents"
    :showActionButton="false"
    forceClose
  >
    <template #content>
      <div
        class="relative bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:p-6"
      >
        <div class="flex flex-col md:flex-row lg:flex-row justify-between">
          <div class="text-base w-auto lg:w-[200px] md:w-[190px]">
            <ECombobox
              v-model="sortBy"
              name="sorts"
              :options="sorts"
              option-name="name"
              value-key="value"
              placeholder="Sort By"
              :multiple="false"
              :filterable="false"
              :clearable="false"
            />
          </div>
          <ExportButton />
        </div>
        <div class="w-full">
          <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
            <li
              v-for="toggle in toggles"
              :key="toggle.id"
              class="-mb-px mr-2 last:mr-0 flex-auto text-center"
            >
              <button
                type="button"
                class="text-xs font-normal uppercase px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer hover:bg-untitled-gray-200 hover:text-untitled-gray-800 w-full"
                @click="handleToggleTabs(toggle.id)"
                :class="{
                  'text-untitled-gray-600 bg-white': openTab !== toggle.id,
                  'text-untitled-gray-800 bg-untitled-gray-200':
                    openTab === toggle.id,
                }"
              >
                {{ toggle.name }}
                <span v-if="toggle.count >= 0" class="text-untitled-gray-500">
                  ({{ toggle.count }})
                </span>
              </button>
            </li>
          </ul>
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 z-0">
            <div class="flex-auto">
              <div class="tab-content tab-space">
                <!-- Units Tab -->
                <div :class="{ hidden: openTab !== 1, block: openTab === 1 }">
                  <LoadingState v-if="loadingContent" class="mx-auto text-center" />
                  <div v-else>
                    <ul
                      v-if="filteredUnitsList.length > 0"
                      role="list"
                      class="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 overflow-auto"
                      :style="filteredUnitsList.length < 3 ? 'height:auto' : 'height: 600px;'"
                    >
                      <li
                        class="grid py-6 px-4 shadow-md m-2"
                        v-for="data in filteredUnitsList"
                        :key="data.unit?.id"
                      >
                        <div class="flex-shrink-0 aspect-auto">
                          <img
                            v-if="data.thumbnail?.includes('google')"
                            class="w-full h-40"
                            v-lazy="{
                              src: 'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
                              error: 'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
                              loading: 'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...',
                            }"
                          />
                          <img
                            v-else-if="data?.thumbnail"
                            v-lazy="{
                              src: data?.thumbnail,
                              error: 'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
                              loading: 'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...',
                            }"
                            class="w-full h-40"
                          />
                          <img
                            v-else
                            src="https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg"
                            class="w-full h-40"
                          />
                        </div>

                        <div class="mt-2 flex flex-1 flex-col">
                          <div>
                            <div class="flex justify-between">
                              <h4 class="text-sm">
                                <a href="#" class="font-medium text-gray-700 hover:text-gray-800">
                                  {{ data.unit?.name }}
                                </a>
                              </h4>
                            </div>
                          </div>

                          <div class="grid">
                            <div class="w-full mx-auto">
                              <div class="flex justify-between pt-5">
                                <p class="text-xs text-left">
                                  <span v-if="data.completed_at">
                                    Completed Date: {{ data.completed_at }}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <br />
                          <p
                            class="text-xs text-center text-untitled-gray-500 font-bold"
                          >
                            <VButton
                              @click="goToUnit(data.unit_id)"
                              type="button"
                              class="font-medium text-xs"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-3 h-3 mr-1"
                              >
                                <path
                                  stroke-linecap="round"
                                  d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
                                />
                              </svg>
                              View Unit
                            </VButton>
                          </p>
                        </div>
                      </li>
                    </ul>
                    <p v-else class="text-center text-untitled-gray-500">
                      No Completed Units Found
                    </p>
                  </div>
                </div>

                <!-- Modules Tab -->
                <div :class="{ hidden: openTab !== 2, block: openTab === 2 }">
                  <LoadingState v-if="loadingContent" class="mx-auto text-center" />
                  <div v-else>
                    <ul
                      v-if="filteredModuleList.length > 0"
                      role="list"
                      class="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 overflow-auto"
                      :style="filteredModuleList.length < 3 ? 'height:auto' : 'height: 600px;'"
                    >
                      <li
                        class="grid py-6 px-4 shadow-md m-2"
                        v-for="module in filteredModuleList"
                        :key="module.module_id"
                      >
                        <div class="flex-shrink-0 aspect-auto">
                          <img
                            v-if="module?.thumbnail"
                            v-lazy="{
                              src: module.thumbnail,
                              error: 'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
                              loading: 'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...',
                            }"
                            class="w-full h-40"
                          />
                          <img
                            v-else
                            src="https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg"
                            class="w-full h-40"
                          />
                        </div>

                        <div class="mt-2 flex flex-1 flex-col">
                          <div>
                            <div class="flex justify-between">
                              <h4 class="text-sm">
                                <a href="#" class="font-medium text-gray-700 hover:text-gray-800">{{ module.name }}</a>
                              </h4>
                            </div>
                          </div>
                          <div class="grid">
                            <div class="w-full mx-auto">
                              <div class="flex justify-between pt-5">
                                <p class="text-xs text-left">
                                  <span v-if="module.completed_at">
                                    Completed Date: {{ module.completed_at }}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <br />
                          <p
                            class="text-xs text-center text-untitled-gray-500 font-bold"
                          >
                            <VButton
                              @click="goToUnit(module.first_unit_id)"
                              type="button"
                              class="font-medium text-xs"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-3 h-3 mr-1"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                />
                              </svg>
                              View Module
                            </VButton>
                          </p>
                        </div>
                      </li>
                    </ul>
                    <p v-else class="text-center text-untitled-gray-500">
                      No Completed Modules Found
                    </p>
                  </div>
                </div>

                <!-- Courses Tab -->
                <div :class="{ hidden: openTab !== 3, block: openTab === 3 }">
                  <LoadingState v-if="loadingContent" class="mx-auto text-center" />
                  <div v-else>
                    <ul
                      v-if="filteredCourseList.length > 0"
                      role="list"
                      class="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 overflow-auto"
                      :style="
                        filteredCourseList.length < 3
                          ? 'height:auto'
                          : 'height: 600px;'
                      "
                    >
                      <li
                        class="grid py-6 px-4 shadow-md m-2"
                        v-for="course in filteredCourseList"
                        :key="course.course_id"
                      >
                        <div class="flex-shrink-0 aspect-auto">
                          <div>
                            <img
                              v-if="course.thumbnail"
                              class="w-full h-40"
                              v-lazy="{
                                src: course.thumbnail,
                                error:
                                  'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
                                loading:
                                  'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...',
                              }"
                             alt="Thumbnail"
                            />
                            <img
                              v-else
                              src="https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg"
                              class="w-full h-40"
                              alt="Thumbnail"
                            />
                          </div>
                        </div>

                        <div class="mt-2 flex flex-1 flex-col">
                          <div>
                            <div class="flex justify-between">
                              <h4 class="text-sm">
                                <a
                                  href="#"
                                  class="font-medium text-gray-700 hover:text-gray-800"
                                >
                                  {{ course.name }}</a
                                >
                              </h4>
                            </div>
                          </div>

                          <div class="grid">
                            <div class="w-full mx-auto">
                              <div
                                class="flex justify-between pt-5 text-left"
                              >
                                <p class="text-xs text-left">
                                  <span v-if="course.completed_at">
                                    Completed Date:
                                    {{ course.completed_at }}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <br />
                          <p
                            class="text-xs text-untitled-gray-500 font-bold text-center"
                          >
                            <VButton
                              @click="goToUnit(course.first_unit_id)"
                              type="button"
                              class="font-medium text-xs"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-3 h-3 mr-1"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                />
                              </svg>
                              View Course
                            </VButton>
                          </p>
                        </div>
                      </li>
                    </ul>
                    <div v-else class="text-center">
                      <p class="text-untitled-gray-500">
                        No completed courses found.
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Playlist Tab -->
                <div :class="{ hidden: openTab !== 4, block: openTab === 4 }">
                  <LoadingState v-if="loadingContent" class="mx-auto text-center" />
                  <div v-else>
                    <ul
                      v-if="filteredPlaylist.length > 0"
                      role="list"
                      class="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 overflow-auto"
                      :style="filteredPlaylist.length < 3 ? 'height:auto': 'height: 600px;'"
                    >
                      <li
                        class="grid py-6 px-4 shadow-md m-2"
                        v-for="playlist in filteredPlaylist"
                        :key="playlist.playlist_id"
                      >
                        <div class="flex-shrink-0 aspect-auto">
                          <img
                            v-if="playlist?.thumbnail"
                            v-lazy="{
                              src: playlist.thumbnail,
                              error: 'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
                              loading: 'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...',
                            }"
                            class="w-full h-40"
                          />
                          <img
                            v-else
                            src="https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg"
                            class="w-full h-40"
                          />
                        </div>

                        <div class="mt-2 flex flex-1 flex-col">
                          <div>
                            <div class="flex justify-between">
                              <h4 class="text-sm">
                                <a href="#" class="font-medium text-gray-700 hover:text-gray-800">{{ playlist.name }}</a>
                              </h4>
                            </div>
                          </div>

                          <div class="grid">
                            <div class="w-full mx-auto">
                              <div class="flex justify-between pt-5">
                                <p class="text-xs text-left">
                                  <span v-if="playlist.completed_at">
                                    Completed Date: {{ playlist.completed_at }}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <br />
                          <p class="text-xs text-center text-untitled-gray-500 font-bold">
                            <VButton
                              @click="goToUnit(playlist.first_unit_id, playlist.playlist_id, playlist.type)"
                              type="button"
                              class="font-medium text-xs"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-3 h-3 mr-1"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                />
                              </svg>
                              View Playlist
                            </VButton>
                          </p>
                        </div>
                      </li>
                    </ul>
                    <p v-else class="text-center text-untitled-gray-500">
                      No Completed Playlists Found
                    </p>
                  </div>
                </div>

                <!-- Scorms Tab -->
                <div :class="{ hidden: openTab !== 5, block: openTab === 5 }">
                  <LoadingState v-if="loadingContent" class="mx-auto text-center" />
                  <div v-else>
                    <ul
                      v-if="filteredScormList.length > 0"
                      role="list"
                      class="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 overflow-auto"
                      :style="filteredScormList.length < 3 ? 'height:auto' : 'height: 600px;'"
                    >
                      <li
                        class="grid py-6 px-4 shadow-md m-2"
                        v-for="scorm in filteredScormList"
                        :key="scorm.unit_id"
                      >
                        <div class="flex-shrink-0 aspect-auto">
                          <div>
                            <img
                              v-if="scorm.thumbnail"
                              class="w-full h-40"
                              v-lazy="{
                                src: scorm.thumbnail,
                                error: 'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
                                loading: 'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...',
                              }"
                              alt="Thumbnail"
                            />
                            <img
                              v-else
                              src="https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg"
                              class="w-full h-40"
                              alt="Thumbnail"
                            />
                          </div>
                        </div>

                        <div class="mt-2 flex flex-1 flex-col">
                          <div>
                            <div class="flex justify-between">
                              <h4 class="text-sm">
                                <a href="#" class="font-medium text-gray-700 hover:text-gray-800">
                                  {{ scorm.unit.name }}</a
                                >
                              </h4>
                            </div>
                          </div>

                          <div class="grid">
                            <div class="w-full mx-auto">
                              <div class="flex justify-between pt-5 text-left">
                                <p class="text-xs text-left">
                                  <span v-if="scorm.completed_at">
                                    Completed Date: {{ scorm.completed_at }}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <br />
                          <p
                            class="text-xs text-untitled-gray-500 font-bold text-center"
                          >
                            <VButton
                              @click="goToUnitScorm(scorm.unit)"
                              type="button"
                              class="font-medium text-xs"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-3 h-3 mr-1"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                />
                              </svg>
                              View Pryor
                            </VButton>
                          </p>
                        </div>
                      </li>
                    </ul>
                    <div v-else class="text-center">
                      <p class="text-untitled-gray-500">
                        No completed Pryor Courses found.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </GlobalDialog>
</template>

<style scoped lang="scss">

</style>
