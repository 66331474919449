<script setup>
import { useAuthStore } from '@/stores';
import { ElPopover } from 'element-plus';
import { computed } from 'vue';
import WeeklyReportSettings from '../WeeklyReportSettings.vue';
import { useWeeklyReportStore } from '@/stores/useWeeklyReportSettings';

const weeklyReportStore = useWeeklyReportStore();
const authStore = useAuthStore();

const isSuperAdmin = computed(() => authStore.isSuperAdmin);
const isAccountManager = computed(() => authStore.isAccountManager);
const isMultiStoreManager = computed(() => authStore.isMultiStoreManager);
const routes = [
  {
    name: 'Manage Users',
    to: '/central/manage-users',
    urlType: 'central',
  },
  {
    name: 'Manage Curriculums',
    to: '/manage/curriculums',
    urlType: 'lms',
  },
  {
    name: 'Manage Courses',
    to: '/manage/courses',
    urlType: 'lms',
  },
  {
    name: 'Manage Playlist',
    to: '/manage/playlist',
    urlType: 'lms',
  },
  {
    name: 'Manage Companies',
    to: '/central/companies',
    urlType: 'central',
  },
  {
    name: 'Manage Stores',
    to: '/central/stores',
    urlType: 'central',
  },
  {
    name: 'Usage Report',
    to: '/usage-report',
    urlType: 'lms',
  },
];

const filteredRoutes = computed(() => {
  return routes.filter((route) => {
    if (
      !isSuperAdmin.value &&
      route.name.toLowerCase() === 'manage companies' 
    ) {
      return false;
    }

    if (
      !isSuperAdmin.value &&
      !isAccountManager.value &&
      !isMultiStoreManager.value &&
      route.name.toLowerCase() === "manage stores"
    ) {
      return false;
    }

    if (
      !isSuperAdmin.value &&
      !isAccountManager.value &&
      route.name.toLowerCase() === "manage courses" 
    ) {
      return false;
    }

    if (
      !isSuperAdmin.value &&
      !isAccountManager.value &&
      route.name.toLowerCase() === "manage playlist"
    ) {
      return false;
    }

    if (!isSuperAdmin.value && route.name.toLowerCase() === 'manage curriculums') {
      return false;
    }

    if (!isSuperAdmin.value && route.name.toLowerCase() === 'usage report') {
      return false;
    }

    return true;
  });
});

const handleClickShow = (route) => {
  switch (route.name.toLowerCase()) {
    case 'manage weekly report':
      weeklyReportStore.showDialog();
      break;

    default:
      break;
  }
};
</script>
<template>
  <div v-bind="$attrs">
    <el-popover :width="220" popper-style="padding: 5px !important;z-index: 3000 !important">
      <template #reference>
        <a
          class="text-untitled-gray-500 border-transparent hover:border-untitled-gray-300 border-b-2 hover:text-untitled-gray-700 px-1 pt-1 text-sm w-full h-full flex justify-center items-center font-medium">
          Admin Portal
        </a>
      </template>
      <template #default>
        <div class="w-full flex flex-col justify-start items-start">
          <div class="w-full" v-for="(route, index) in filteredRoutes" :key="index">
            <router-link
              class="w-full px-2 py-2 hover:bg-gray-200 text-sm text-untitled-gray-700 flex justify-start items-start"
              :to="route.to" v-if="route.urlType === 'lms'">
              {{ route.name }}
            </router-link>
            <a :href="route.to" v-else-if="route.urlType === 'central'" target="_blank"
              class="w-full px-2 py-2 hover:bg-gray-200 text-sm text-untitled-gray-700 flex justify-start items-start">{{
                route.name }}</a>
            <button v-else
              class="w-full px-2 py-2 hover:bg-gray-200 text-sm text-untitled-gray-700 flex justify-start items-start"
              @click="handleClickShow(route)">
              {{ route.name }}
            </button>
          </div>
        </div>
      </template>
    </el-popover>
    <WeeklyReportSettings />
  </div>
</template>
<style lang="scss" scoped></style>
