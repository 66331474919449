<template>
  <li
    class="col-span-1 flex flex-col cursor-pointer"
    @click="handleClickWidget"
  >
    <ElTooltip
      :content="description"
      effect="dark"
      :raw-content="true"
      placement="top-start"
    >
      <div
        class="w-full flex flex-col gap-2 px-4 justify-center items-center h-32 bg-untitled-gray-100 hover:bg-untitled-gray-200 cursor-pointer rounded-md shadow-lg"
      >
        <p class="text-4xl font-semibold text-untitled-gray-600">{{ value }}</p>
        <p class="text-xs text-untitled-gray-600 text-center font-medium">
          {{ text }}
        </p>
      </div>
    </ElTooltip>
  </li>
</template>

<script setup>
import { ElTooltip } from "element-plus";
import { computed } from "vue";
import { defineProps } from "vue";
import { useRouter } from "vue-router";
const props = defineProps({
  text: {
    type: String,
    default: "",
  },
  value: {
    type: Number,
    default: null,
  },
  description: {
    type: String,
    default: "",
  },
  id: {
    type: String,
  },
});

const router = useRouter();

const text = computed(() => props.text);
const value = computed(() => props.value);
const description = computed(() => props.description);
const id = computed(() => props.id);

const handleClickWidget = () => {
  if (id.value === "totalCompleted") {
    router.push({
      name: "reports",
      query: {
        tab: "assignment-history",
        statuses: ["Completed"],
      },
    });
    return;
  }
  if (id.value === "totalInProgress") {
    router.push({
      name: "reports",
      query: { tab: "assignment-history", statuses: ["In Progress"] },
    });
    return;
  }

  router.push({
    name: "reports",
    query: { tab: "assignment-history", statuses: ["Overdue"] },
  });
};
</script>

<style lang="scss" scoped></style>
