<script setup>

import NotificationDialog from "@/components/partials/NotificationDialog.vue";
import {
  Combobox,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/solid";
import { PaperAirplaneIcon } from "@heroicons/vue/solid";
import InputEmailDialog from "@/components/dialogs/InputEmailDialog";
import { ref } from "vue";
import { useCompletedContentStore } from "@/stores/home/useCompletedContent.store";

const completedContentStore = useCompletedContentStore();

const publishingOptions = [
  {
    exportOption: "Send as email",
    description: "Send report as an email",
    current: false,
    type: "email",
  },
];

const exportSelected = ref(publishingOptions[0]);
const showInputEmailDialog = ref(false);
const errorData = ref(null);
const isLoading = ref(false);
const showNotificationDialog = ref(false);
const message = ref("");

const exportCompletedContents = () => {
  errorData.value = null;
  showInputEmailDialog.value = true;
}

const handleSendEvent = async (emails) => {
  await completedContentStore.handleExportCompletedContent(emails);
  showNotificationDialog.value = false;
}

</script>

<template>
  <div class="cursor-pointer z-20 inline-flex w-[auto]">
    <div class="relative">
      <Combobox as="div" v-model="exportSelected">
        <ComboboxLabel class="sr-only">Change publish status</ComboboxLabel>
        <div class="relative">
          <div
            class="inline-flex divide-x divide-untitled-gray-100 rounded-md shadow-sm"
          >
            <div
              class="relative z-0 inline-flex divide-x divide-untitled-gray-100 rounded-md shadow-sm"
            >
              <div
                :disabled="isLoading"
                @click="exportCompletedContents()"
                class="relative inline-flex items-center rounded-l-md border border-transparent bg-untitled-gray-200 py-2 pl-3 pr-4 text-untitled-gray-800 shadow-sm"
              >
                <PaperAirplaneIcon class="w-4 text-untitled-gray-500" />
                <h5 class="ml-2.5 text-sm font-medium">
                  {{ exportSelected.exportOption }}
                </h5>
              </div>
            </div>
          </div>

          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ComboboxOptions
              class="absolute left-0 mt-2 -mr-1 w-48 origin-top-right divide-y divide-untitled-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0"
            >
              <ComboboxOption
                as="template"
                v-for="option in publishingOptions"
                :key="option.exportOption"
                :value="option"
                v-slot="{ active, exportSelected }"
              >
                <li
                  :class="[
                    active
                      ? 'text-white bg-untitled-gray-500'
                      : 'text-untitled-gray-900',
                    'relative cursor-pointer select-none p-4 text-sm',
                  ]"
                >
                  <div class="flex flex-col">
                    <div class="flex justify-between">
                      <p
                        :class="
                          exportSelected ? 'font-semibold' : 'font-normal'
                        "
                      >
                        {{ option.exportOption }}
                      </p>
                      <span
                        v-if="exportSelected"
                        :class="
                          active ? 'text-white' : 'text-untitled-gray-500'
                        "
                      >
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </div>
                    <p
                      :class="[
                        active
                          ? 'text-untitled-gray-200'
                          : 'text-untitled-gray-500',
                        'mt-2',
                      ]"
                    >
                      {{ option.description }}
                    </p>
                  </div>
                </li>
              </ComboboxOption>
            </ComboboxOptions>
          </transition>
        </div>
      </Combobox>
    </div>
  </div>

  <InputEmailDialog
    :open="showInputEmailDialog"
    @cancelEvent="showInputEmailDialog = false"
    @sendEvent="handleSendEvent"
    :error="errorData"
    :loading="isLoading"
  />

  <NotificationDialog
    v-if="showNotificationDialog"
    @close="showNotificationDialog = false"
    :content="message"
  />
</template>

<style scoped lang="scss">

</style>
