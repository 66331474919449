import { http } from "@/composable/httpService";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import eventBus from "@/plugins/eventBus";

export const useCompletedContentStore = defineStore("completedContent", () => {
  const loadingContent = ref(false);
  const loadingContentCard = ref(false);
  const totalCount = ref(0);
  const units = ref([]);
  const courses = ref([]);
  const modules = ref([]);
  const playlists = ref([]);
  const scorm = ref([]);

  const handleExportCompletedContent = async (emailArray = []) => {
    try {
      const payload = {
        emails: emailArray,
      }
      const response = await http().post(`/v3/completed-contents/export`, payload);
      if (response.status === 200) {
        handleNotification();
      }
    } catch (error) {
      console.error("Error fetching completed content:", error);
      useHandleErrorStatus(error);
    }
  };

  const handleFetchCompletedContent = async () => {
    loadingContent.value = true;
    try {
      const response = await http().get(`/v3/completed-contents/data`);
      if (response.status === 200) {
        units.value = response.data.units || [];
        courses.value = response.data.courses || [];
        modules.value = response.data.modules || [];
        playlists.value = response.data.playlists || [];
        scorm.value = response.data.scorm || [];
      }
    } catch (error) {
      console.error("Error fetching completed content:", error);
      useHandleErrorStatus(error);
    } finally {
      loadingContent.value = false;
    }
  };

  const handleFetchTotalCount = async () => {
    try {
      loadingContentCard.value = true;
      const response = await http().get(`/v3/completed-contents/count`);
      totalCount.value = response.data.totalCount;
    } catch (error) {
      useHandleErrorStatus(error);
    } finally {
      loadingContentCard.value = false;
    }
  }

  const updateUnits = (newUnits) => {
    units.value = newUnits;
  };

  const updateCourses = (newCourses) => {
    courses.value = newCourses;
  };

  const updateModules = (newModules) => {
    modules.value = newModules;
  };

  const updatePlaylists = (newPlaylists) => {
    playlists.value = newPlaylists;
  };

  const updateScorm = (newScorm) => {
    scorm.value = newScorm;
  };

  const notification = (text) => {
    eventBus.$emit("CREATE_NOTIFICATION", text, "success", 6500);
  };

  const handleNotification = () => {
    notification(
      "Your request is in progress, we will be sending the report to your email as soon as we're done generating it."
    );
  };

  return {
    loadingContent,
    loadingContentCard,
    totalCount,
    units,
    courses,
    modules,
    playlists,
    scorm,
    handleFetchCompletedContent,
    handleFetchTotalCount,
    handleExportCompletedContent,
    updateUnits,
    updateCourses,
    updateModules,
    updatePlaylists,
    updateScorm,
  };
});
