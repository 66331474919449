<template>
  <div class="flex flex-col gap-3">
    <!-- Filters -->
    <FeaturedContentFilters />
    <!-- end filters -->
    <KeepAlive>
      <component :is="display" />
    </KeepAlive>
    <div class="w-full flex justify-center items-center">
      <ElButton
        v-if="!isLastPage && (!loadingContents || !initialLoading)"
        @click="handleClickLoadMore"
        type="primary"
        >Load More</ElButton
      >
    </div>
  </div>
</template>

<script setup>
import { useHomeTabsStore } from "@/stores/home/useHomeTabs.store";
import FeaturedContentFilters from "./featured-content/FeaturedContentFilters.vue";
import { storeToRefs } from "pinia";
import {
  computed,
  defineAsyncComponent,
  onMounted,
  onUnmounted,
  watch,
} from "vue";
import { useHomeFeaturedContentStore } from "@/stores/home/useHomeFeaturedContent.store";
import { ElButton } from "element-plus";
const FeaturedContentList = defineAsyncComponent(() =>
  import("./featured-content/FeaturedContentList.vue")
);
const FeaturedContentGrid = defineAsyncComponent(() =>
  import("./featured-content/FeaturedContentGrid.vue")
);

const homeFeaturedContentStore = useHomeFeaturedContentStore();
const homeTabsStore = useHomeTabsStore();

const { playlistPage, isLastPage, loadingContents, initialLoading, sortBy } =
  storeToRefs(homeFeaturedContentStore);
const { listType } = storeToRefs(homeTabsStore);

const display = computed(() => {
  if (listType.value === "list") {
    return FeaturedContentList;
  }

  return FeaturedContentGrid;
});

const handleFetchFeaturedContent = () => {
  homeFeaturedContentStore.handleFetchFeaturedContent();
};

const handleFetchNewContents = () => {
  homeFeaturedContentStore.handleNewContents();
};

const handleClickLoadMore = () => {
  playlistPage.value++;
  handleFetchFeaturedContent();
};

onMounted(() => {
  handleFetchFeaturedContent();
  handleFetchNewContents();
});

onUnmounted(() => {
  playlistPage.value = 1;
  isLastPage.value = false;
});

watch(sortBy, () => {
  playlistPage.value = 1;
  isLastPage.value = false;
  initialLoading.value = true;
  handleFetchFeaturedContent();
});
</script>

<style lang="css" scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
