<template>
  <div
    class="w-full flex flex-col xl:flex-row gap-2 bg-untitled-gray-50 max-w-[2300px] mx-auto"
  >
    <div
      class="flex-1 flex flex-col gap-2 lg:border-r border-untitled-gray-300 p-2 sm:p-4"
    >
      <!-- tabs -->
      <HomeMainTabs />
      <!-- end of tabs -->
      <div class="w-full">
        <KeepAlive>
          <component :is="display" />
        </KeepAlive>
      </div>
    </div>
    <div class="w-full xl:w-2/6 flex flex-col gap-2 p-2 sm:p-4">
      <HomeRightSection />
    </div>
  </div>
  <el-backtop :right="90" :bottom="90" />
</template>

<script setup>
import HomeMainTabs from "./partials/HomeMainTabs.vue";
import HomeRightSection from "./HomeRightSection.vue";
import { useHomeTabsStore } from "@/stores/home/useHomeTabs.store";

import { storeToRefs } from "pinia";
import { defineAsyncComponent, onMounted } from "vue";
import { computed } from "vue";
import { useAllAvailableCourseStore } from "@/stores/useAllAvailableCourse.store";
import { ElBacktop } from "element-plus";
import FeaturedContent from "@/components/new-home/main-display/FeaturedContent.vue";

const EnrolledCourses = defineAsyncComponent(() =>
  import("./main-display/EnrolledCourses.vue")
);
const LearningPath = defineAsyncComponent(() =>
  import("./main-display/LearningPath.vue")
);
const AvailableContent = defineAsyncComponent(() =>
  import("./main-display/AvailableContent.vue")
);

const allAvailableCourseStore = useAllAvailableCourseStore();
const homeMainTabsStore = useHomeTabsStore();
const { selectedMainTab } = storeToRefs(homeMainTabsStore);

const display = computed(() => {
  if (selectedMainTab.value === "enrolled") {
    return EnrolledCourses;
  }
  if (selectedMainTab.value === "playlist") {
    return LearningPath;
  }
  if (selectedMainTab.value === "content") {
    return AvailableContent;
  }
  return FeaturedContent;
});

onMounted(() => {
  allAvailableCourseStore.handleFetchCategoriesWithCourses();
});
</script>

<style lang="scss" scoped></style>
