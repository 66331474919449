import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { http } from "@/composable/httpService";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useAssignToUsersStore = defineStore("assignTOUsersStore", () => {
  const users = ref([]);
  const loadingUsers = ref(false);
  const groups = ref([]);
  const loadingGroups = ref(false);
  const dealers = ref([]);
  const loadingDealers = ref(false);
  const companies = ref([]);
  const loadingCompanies = ref(false);

  const handleFetchUsers = async (params) => {
    try {
      loadingUsers.value = true;
      const response = await http().get("/client/lms/fetchUsersByDealer", {
        params,
      });
      users.value = response.data;
    } catch (error) {
      useHandleErrorStatus(error);
    } finally {
      loadingUsers.value = false;
    }
  };

  const handleFetchGroups = async (params) => {
    try {
      loadingGroups.value = true;
      const response = await http().get("/groups", { params });
      groups.value = response.data;
    } catch (error) {
      useHandleErrorStatus(error);
    } finally {
      loadingGroups.value = false;
    }
  };

  const handleFetchDealers = async (params) => {
    try {
      loadingDealers.value = true;
      const response = await http().get("/companies-and-dealers", { params });
      dealers.value = response.data?.data?.dealers;
    } catch (error) {
      useHandleErrorStatus(error);
    } finally {
      loadingDealers.value = false;
    }
  };

  const handleFetchCompanies = async (params) => {
    try {
      loadingCompanies.value = true;
      const response = await http().get("/companies-and-dealers", { params });
      companies.value = response.data?.data?.companies;
      dealers.value = response.data?.data?.dealers;
    } catch (error) {
      useHandleErrorStatus(error);
    } finally {
      loadingCompanies.value = false;
    }
  };

  return {
    handleFetchUsers,
    handleFetchGroups,
    handleFetchDealers,
    handleFetchCompanies,
    users,
    loadingUsers,
    companies,
    loadingCompanies,
    dealers,
    loadingDealers,
    groups,
    loadingGroups,
  };
});
