<template>
  <DeferredContent @onLoadComponent="handleFetchTeamProgress">
    <div class="w-full flex flex-col gap-3">
      <div
        class="border-b border-untitled-gray-200 flex justify-center py-2 sm:py-6"
      >
        <span class="text-sm font-semibold text-untitled-gray-600"
          >Your Team’s Progress</span
        >
      </div>
      <ul
        role="list"
        class="grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 grid w-full"
        v-if="!loadingProgress && teamProgress.length && !isErrorTeamProgress"
      >
        <TeamProgressCard
          v-for="report in teamProgress"
          :key="report.key"
          :text="report.title"
          :value="report.value"
          :id="report.key"
          :description="wrapTooltipContent(report.description)"
        />
      </ul>
      <LoadingTeamProgressCards v-if="loadingProgress" />
      <MessageContainer
        v-if="!loadingProgress && isErrorTeamProgress"
        text="There was a problem with the team's progress, please click Reload."
        class="min-h-32 border rounded-md border-untitled-gray-200"
      >
        <ElButton :icon="CursorClickIcon" @click="handleFetchTeamProgress"
          >Reload</ElButton
        >
      </MessageContainer>
    </div>
  </DeferredContent>
</template>

<script setup>
import TeamProgressCard from "./TeamProgressCard.vue";
import { computed } from "vue";
import { useTeamProgressStore } from "@/stores/home/useTeamProgress.store";
import DeferredContent from "@/components/utilities/DeferredContent.vue";
import { storeToRefs } from "pinia";
import LoadingTeamProgressCards from "./partials/LoadingTeamProgressCards.vue";
import { wrapTooltipContent } from "@/composable/wrapTooltipContent";
import MessageContainer from "@/components/utilities/MessageContainer.vue";
import { ElButton } from "element-plus";
import { CursorClickIcon } from "@heroicons/vue/solid";

const teamProgressStore = useTeamProgressStore();
const { loadingProgress, isErrorTeamProgress } = storeToRefs(teamProgressStore);

const teamProgress = computed(() => teamProgressStore.progress);

const handleFetchTeamProgress = () => {
  teamProgressStore.handleFetchAssignedSummary();
};
</script>

<style lang="scss" scoped></style>
