<template>
  <li class="col-span-1 flex flex-col">
    <div
      class="w-full flex flex-col gap-2 px-4 justify-center items-center h-32 bg-untitled-gray-100 hover:bg-untitled-gray-200 cursor-pointer rounded-md shadow-lg"
      @click="handleClickCard"
    >
      <p class="text-4xl font-semibold text-untitled-gray-600">{{ value }}</p>
      <p class="text-xs text-untitled-gray-600 text-center font-medium">
        {{ text }}
      </p>
    </div>
  </li>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
const props = defineProps({
  text: {
    type: String,
    default: "",
  },
  value: {
    type: Number,
    default: null,
  },
  prop: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["onClickCard"]);

const text = computed(() => props.text);
const value = computed(() => props.value);
const prop = computed(() => props.prop);

const handleClickCard = () => {
  emit("onClickCard", prop.value);
};
</script>

<style lang="scss" scoped></style>
