import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import axios from "axios";
import { http } from "@/composable/httpService";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
export const useAuthStore = defineStore({
  persist: true,
  id: "auth",
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: null,
    returnUrl: null,
    token: null,
    scormAccess: false,
  }),
  actions: {
    async login(clientid) {
      // auth Route
      axios
        .post("/api/sso-post-login", {
          clientid,
        })
        .then(({ data }) => {
          // update pinia state
          this.user = data.user;
          axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
          this.scormAccess = false;
          // store user details and jwt in local storage to keep user logged in between page refreshes
          localStorage.setItem("user", JSON.stringify(data.user));
          localStorage.setItem("bearer_token", JSON.stringify(data.token));

          // redirect to previous url or default to home page
          useRouter().push("/");
        })
        .catch(() => {
          //
        });
    },
    logout() {
      this.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("bearer_token");
      useRouter().push("/login");
    },
    async getAuth() {
      try {
        const response = await http().get("/auth/user");
        this.user = response.data;
      } catch (error) {
        useHandleErrorStatus(error);
      }
    },
  },
  getters: {
    fullName() {
      const name = this.user.name.split(" ");
      const capitalizeFirstLetter =
        name[0].charAt(0).toUpperCase() + name[0].slice(1);
      const endsWithS = capitalizeFirstLetter.endsWith("s");
      return endsWithS ? capitalizeFirstLetter : `${capitalizeFirstLetter}'s`;
    },
    isSuperAdmin() {
      if (!this.user?.id) {
        return false;
      }

      return this.user.roles.some(
        (role) => role.name === "super-administrator"
      );
    },
    isEnableNewHomePage() {
      return this.user.is_enabled_new_homepage;
    },
    isEnabledScormUpload() {
      return this.user.is_enabled_scorm_upload;
    },
    isScormAccess() {
      if (this.scormAccess) {
        return this.scormAccess;
      }
      return false;
    },
    isSingleUser() {
      if (!this.isSuperAdmin && this.user?.dealer?.id) {
        return Boolean(this.user.dealer.is_single_user);
      }

      return false;
    },
    isNotSingleRooftop() {
      if (!this.user) {
        return false;
      }

      if (!this.isSuperAdmin && this.user?.dealer?.is_automotive) {
        return false;
      }

      return true;
    },
    isDealerManager() {
      if (!this.user?.id) {
        return false;
      }

      return this.user.roles.some(
        (role) => role.name === "specific-dealer-manager"
      );
    },
    isAccountManager() {
      if (!this.user?.id) {
        return false;
      }

      return this.user.roles.some((role) => role.name === "account-manager");
    },
    isMultiStoreManager() {
      if (!this.user?.id) {
        return false;
      }

      return this.user.roles.some((role) => role.name === "multi-store-manager");
    },
    isLearner() {
      if (!this.user?.id) {
        return false;
      }

      return this.user.roles.some((role) => role.name === "salesperson");
    },
    roles() {
      if (!this.user.id) {
        return [];
      }
      return this.user.roles.map((role) => role.name.replace("-", " "));
    },
    rolesFriendly() {
      if (!this.user.id) {
        return [];
      }
      return this.user.roles.map((role) => role.friendly_name);
    }
  },
});
