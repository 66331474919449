<template>
  <div class="bg-white w-full px-4 pt-5 pb-8 shadow-md mb-2">
    <div class="mb-3 md:flex items-center justify-between">
      <div class="sm:w-2/5">
        <h3 class="font-bold text-2xl text-untitled-gray-700 line-clamp-2">
          <el-tooltip
            :content="wrapTooltipContent(title)"
            effect="dark"
            :raw-content="true"
            placement="top-start"
          >
            {{ title }}
          </el-tooltip>
        </h3>
      </div>
      <div class="flex flex-row gap-2 justify-end items-center">
        <div
          class="flex gap-1 peer-hover:hidden beer-hover:hidden items-center text-untitled-gray-600"
        >
          <template v-if="videoContent.length > 0">
            <VideoCameraIcon class="h-4 w-4" aria-hidden="true" />
            <p class="text-xs">
              {{
                videoContent.filter((x) => x.is_completed == true)?.length
              }}/{{ videoContent.length }} Videos Completed
            </p>
          </template>

          <template v-if="textContent.length > 0">
            <DocumentTextIcon class="h-4 w-4" aria-hidden="true" />
            <p class="text-xs">
              {{ textContent.filter((x) => x.is_completed == true)?.length }}/{{
                textContent.length
              }}
              Text Units Completed
            </p>
          </template>
        </div>
        <template v-if="dueDate">
          <div
            class="flex gap-1 peer-hover:hidden items-center text-untitled-gray-600"
          >
            <CalendarIcon class="h-4 w-4" aria-hidden="true" />

            <p class="text-xs">{{ dueDate }}</p>
          </div>
        </template>
        <div
          class="flex gap-1 peer-hover:hidden items-center text-untitled-gray-600"
        ></div>
      </div>
    </div>
    <div class="md:flex items-center gap-3">
      <div class="md:w-full">
        <span @click="goToLearningPath()">
          <div class="relative cursor-pointer">
            <img
              v-lazy="{
                src: thumbnail
                  ? thumbnail
                  : 'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
                error:
                  'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
                loading:
                  'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...',
              }"
              class="aspect-video w-full lg:w-[60%]"
            />

            <div
              class="absolute w-full lg:w-[60%] bottom-0 h-full object-center inset-0 place-items-center bg-gradient-to-t from-black opacity-50 text-white text-xs text-center leading-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="mt-[18%] object-center place-items-center inset-0 mx-auto w-12 h-12"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                />
              </svg>
            </div>
          </div>
        </span>
      </div>
      <div class="w-full">
        <div class="container mx-auto">
          <div class="w-full gap-2 mx-auto mt-5 md:mt-5">
            <div
              class="flex justify-end gap-1 flex-col md:flex-row"
              v-if="isSuperAdmin || isOwner"
            >
              <AssignToUser
                v-if="contents.length"
                :playlist-id="pathId"
                isPlaylist
                v-slot="{ clickAssign }"
                title="Assign Playlist"
                label="Assign Playlist"
                v-has-restrict-learner
              >
                <a
                  href="javascript:"
                  @click="clickAssign()"
                  class="flex w-full justify-center rounded-md border border-transparent bg-untitled-gray-200 py-2 px-4 font-medium text-untitled-gray-800 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-untitled-gray-500 focus:ring-offset-2 text-xs"
                  v-has-restrict-learner
                >
                  <small>Assign Playlist</small>
                </a>
              </AssignToUser>
              <router-link
                :to="redirectToEdit()"
                class="flex w-full justify-center rounded-md border border-transparent bg-untitled-gray-200 py-2 px-4 font-medium text-untitled-gray-800 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-untitled-gray-500 focus:ring-offset-2 text-xs"
              >
                <small>Edit Playlist</small>
              </router-link>
              <a
                href="#"
                @click="checkPlaylistAssigned"
                class="flex w-full justify-center rounded-md border border-transparent bg-untitled-gray-200 py-2 px-4 font-medium text-untitled-gray-800 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-untitled-gray-500 focus:ring-offset-2 text-xs"
              >
                <small>Delete Playlist</small>
              </a>
            </div>
            <div class="flex justify-end gap-1 flex-col md:flex-row" v-else>
              <AssignToUser
                :playlist-id="pathId"
                isPlaylist
                v-slot="{ clickAssign }"
                title="Assign Playlist"
                label="Assign Playlist"
                v-has-restrict-learner
              >
                <a
                  href="javascript:"
                  @click="clickAssign()"
                  class="flex w-full justify-center rounded-md border border-transparent bg-untitled-gray-200 py-2 px-4 font-medium text-untitled-gray-800 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-untitled-gray-500 focus:ring-offset-2 text-xs"
                  v-has-restrict-learner
                >
                  <small>Assign Playlist</small>
                </a>
              </AssignToUser>
              <button
                @click="goToLearningPath()"
                class="flex w-full justify-center rounded-md border border-transparent bg-untitled-gray-200 py-2 px-4 font-medium text-untitled-gray-800 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-untitled-gray-500 focus:ring-offset-2 text-xs"
              >
                <small>Open Playlist</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <assign-playlist-dialog
    v-if="assignPlaylistDialogOpen"
    :is-open="assignPlaylistDialogOpen"
    :close="closeAssignPlaylistDialog"
    :playlist-id="pathId"
  />
  <ConfirmDialog
    :okButton="deletePlaylist"
    :cancelButton="onClickConfirmCancel"
    :open="confirmShow"
    id="UnitPage-ConfirmDialog-Dialogbox"
    >{{ confirmMessage }}
  </ConfirmDialog>
  <PromptDialog
    :okButton="onClickConfirmCancel"
    :cancelButton="onClickConfirmCancel"
    :open="promptShow"
    id="UnitPage-ConfirmDialog-Dialogbox"
    >{{ confirmMessage }}
  </PromptDialog>
</template>

<script setup>
/* eslint-disable */
import AssignPlaylistDialog from "@/components/learning-paths/AssignPlaylistDialog.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { defineProps, ref, inject, computed, onMounted } from "vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
import PromptDialog from "@/components/dialogs/ConfirmDialog";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores";
import {
  DotsVerticalIcon,
  // DotsHorizontalIcon,
  VideoCameraIcon,
  CalendarIcon,
  // CogIcon,
  PresentationChartLineIcon,
  DocumentTextIcon,
  ClipboardCheckIcon,
} from "@heroicons/vue/outline";
import { find as _find } from "lodash-es";
import { useLearningPathStore } from "@/stores/learningPath";
import { wrapTooltipContent } from "@/composable/wrapTooltipContent";
import { ElTooltip } from "element-plus";
import AssignToUser from "@/components/utilities/AssignToUser.vue";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import dayjs from "dayjs";

const props = defineProps({
  contents: {
    type: Array,
    default: () => [],
  },
  isOwner: {
    type: Boolean,
    required: true,
  },
  reload: {
    type: Function,
  },
  pathId: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    default: null,
  },
  totalVideoDuration: {
    type: String,
    default: null,
  },
  totalVideos: {
    type: Number,
    default: 0,
  },
  totalVideosCompleted: {
    type: Number,
    default: 0,
  },
  videoThumbnail: {
    type: String,
    default: null,
  },
  dueDate: {
    type: String,
    default: null,
  },
  thumbnail: {
    type: String,
    default: null,
  },
  startDate: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: "simple",
  },
});
const authUser = useAuthStore();
const confirmMessage = ref("Are you sure you want to delete this playlist?");
const confirmShow = ref(false);
const axios = inject("axios");
const learningPath = useLearningPathStore();

const videoContent = computed(() => {
  return props.contents.filter(
    (content) =>
      content.type == "video" ||
      content.type == "scorm" ||
      content.type == "custom" ||
      content.type == "s3"
  );
});

const textContent = computed(() => {
  return props.contents.filter((content) => content.type == "text");
});

const isSuperAdmin = computed(() => {
  const user = authUser.$state.user;
  if (!user) {
    return false;
  }
  const isExists = user.roles.find(
    (role) => role.name === "super-administrator"
  );
  return isExists;
});

const router = useRouter();
const assignPlaylistDialogOpen = ref(false);

function goToLearningPath() {
  const { contents, pathId } = props;

  if (!contents.length && props.type !== "custom") {
    redirectToEdit();
  }

  if (!contents.length) {
    router.push({
      name: "Edit Custom Playlist",
      params: {
        id: pathId,
      },
    });

    return;
  }

  const currentUnit = _find(contents, { is_completed: false });
  let unitId = null;

  if (currentUnit === undefined) {
    unitId = props.contents[0].id;
  } else {
    unitId = currentUnit.id;
  }

  if (props.type === "custom") {
    router.push({
      name: "Custom Playlist Unit Page",
      params: {
        id: unitId,
        playlistId: pathId,
      },
    });
  } else {
    router.push({
      name: "units",
      params: {
        id: unitId,
        playlistId: pathId,
      },
    });
  }
}

function openDialog() {
  assignPlaylistDialogOpen.value = true;
}

function redirectToEdit() {
  if (props.type === "custom") {
    return `/custom-playlist/${props.pathId}/edit`;
  } else {
    return "/edit/playlist/" + props.pathId;
  }
}
function closeAssignPlaylistDialog() {
  assignPlaylistDialogOpen.value = false;
}

async function deletePlaylist() {
  await axios.delete(`/api/v3/learning-paths/${props.pathId}`).then(() => {
    learningPath.removeLearningPath(props.pathId);
    confirmShow.value = false;
  });
}

function onClickConfirmCancel() {
  confirmShow.value = false;
  promptShow.value = false;
}

const promptShow = ref(false);

const checkPlaylistAssigned = async () => {
  await getPlaylistAssigneesProgress();
  if (playlistAssignees.value.length > 0) {
    confirmMessage.value =
      "Playlist cannot be deleted. This playlist is already assigned to a user.";
    promptShow.value = true;
    return;
  }
  confirmShow.value = true;
};

const playlistAssignees = ref([]);

const getPlaylistAssigneesProgress = async () => {
  try {
    const response = await axios.get(
      `/api/v3/playlist/${props.pathId}/assignees`
    );
    playlistAssignees.value = response.data;
  } catch (error) {
    useHandleErrorStatus(error);
  }
};

onMounted(async () => {});
</script>

<style scoped></style>
