<template>
  <div class="w-full flex flex-col md:flex-row gap-2">
    <div class="w-full flex flex-col gap-2 lg:flex-row py-3 justify-between">
      <div class="flex flex-col md:flex-row gap-2">
        <div class="w-full md:w-96 flex">
          <ElInput
            size="large"
            :prefix-icon="SearchIcon"
            placeholder="Search"
            @keyup.enter="handleOnSearch"
            v-model="search"
          />
        </div>
        <div class="md:max-w-44 w-full">
          <GroupSelectInput
            v-model="sortBy"
            :options="sorts"
            label="name"
            value="value"
            :show-icon="true"
            :is-icon="true"
            :icon="AdjustmentsIcon"
            placeholder="Sort By"
            :clearable="true"
          />
        </div>
      </div>
      <div>
        <DropdownMenu />
      </div>
    </div>
    <HomeViewList />
  </div>
</template>

<script setup>
import { SearchIcon, AdjustmentsIcon } from "@heroicons/vue/solid";
import { ElInput } from "element-plus";
import DropdownMenu from "@/components/element-components/DropdownMenu.vue";
import HomeViewList from "../../partials/HomeViewList.vue";
import GroupSelectInput from "@/components/layout/inputs/GroupSelectInput.vue";
import { useHomeFeaturedContentStore } from "@/stores/home/useHomeFeaturedContent.store";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const homeFeaturedContentStore = useHomeFeaturedContentStore();
const { sortBy } = storeToRefs(homeFeaturedContentStore);

const search = ref();

const sorts = [
  {
    name: "A-Z",
    value: "a-z",
  },
  {
    name: "Z-A",
    value: "z-a",
  },
];

const handleOnSearch = () => {
  router.push({
    name: "search",
    query: {
      search: search.value,
    },
  });
};
</script>

<style lang="css" scoped></style>
