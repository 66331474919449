<template>
  <DeferredContent @on-load-component="handleFetchReportSummary">
    <div class="rounded-md flex flex-col gap-3">
      <div
        class="w-full flex flex-col justify-center items-center sm:h-32 h-24 gap-3"
      >
        <ElAvatar :size="50" fit="scale-down" :src="profilePhoto">
          <UserIcon class="text-untitled-gray-600 w-6" />
        </ElAvatar>
        <p class="font-semibold text-untitled-gray-600 text-sm">
          {{ fullName }}
        </p>
        <div class="flex flex-row gap-3">
          <span
            class="text-xs font-medium text-untitled-gray-600 capitalize"
            v-for="(role, index) in roles"
            :key="index"
            >({{ role }})</span
          >
        </div>
      </div>
      <div class="w-full">
        <ul
          role="list"
          class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 w-full"
          v-if="!loadingSummary"
        >
          <SummaryCard
            v-for="report in reportSummaryCards"
            :key="report.key"
            :text="report.title"
            :value="report.value"
            :prop="report.key"
            @on-click-card="handleClickCard"
          />
        </ul>
        <LoadingSummaryCards v-else />
      </div>
    </div>
    <CompletedCoursesModal
      v-if="showCompletedCoursesModal"
      v-model="showCompletedCoursesModal"
    />
    <CourseInProgressModal
      v-if="showInProgressCoursesModal"
      v-model="showInProgressCoursesModal"
    />
    <AssignedCourseModal
      v-if="showAssignedCoursesModal"
      v-model="showAssignedCoursesModal"
    />
    <CertificatesEarnedModal
      v-if="showCertificatesEarnedModal"
      v-model="showCertificatesEarnedModal"
    />
  </DeferredContent>
</template>

<script setup>
import { useAuthStore } from "@/stores";
import { UserIcon } from "@heroicons/vue/outline";
import { ElAvatar } from "element-plus";
import { computed, defineAsyncComponent } from "vue";
import SummaryCard from "./SummaryCard.vue";
import { useReportSummaryStore } from "@/stores/home/useReportSummary.store";
import { storeToRefs } from "pinia";
import LoadingSummaryCards from "./partials/LoadingSummaryCards.vue";
import DeferredContent from "@/components/utilities/DeferredContent.vue";
import { ref,watch } from "vue";
import { useDialogStore } from "@/stores/useDialogStore";

const CertificatesEarnedModal = defineAsyncComponent(() =>
  import("@/components/home/my-progress/CertificatesEarnedModal.vue")
);
const AssignedCourseModal = defineAsyncComponent(() =>
  import("@/components/home/my-progress/AssignedCourseModal.vue")
);
const CourseInProgressModal = defineAsyncComponent(() =>
  import("@/components/home/my-progress/CourseInProgressModal.vue")
);
const CompletedCoursesModal = defineAsyncComponent(() =>
  import("@/components/home/my-progress/CompletedCoursesModal.vue")
);

const dialogStore = useDialogStore();
const authStore = useAuthStore();
const reportSummaryStore = useReportSummaryStore();
const { loadingSummary } = storeToRefs(reportSummaryStore);

const roles = computed(() => authStore.rolesFriendly);
const profilePhoto = computed(() => authStore.user?.profile_picture);
const fullName = computed(() => {
  if(authStore.user){
    return authStore.user.name
  }
  return "Guest User"
});
const reportSummaryCards = computed(
  () => reportSummaryStore.reportSummaryCards
);
const showCompletedCoursesModal = ref(false);
const showInProgressCoursesModal = ref(false);
const showAssignedCoursesModal = ref(false);
const showCertificatesEarnedModal = ref(false);

const handleFetchReportSummary = () => {
  reportSummaryStore.handleFetchReportSummary();
};

const handleClickCard = (prop) => {
  if (prop === "courses_completed_count") {
    showCompletedCoursesModal.value = !showCompletedCoursesModal.value;
    return;
  }
  if (prop === "courses_in_progress_count") {
    showInProgressCoursesModal.value = !showInProgressCoursesModal.value;
    return;
  }
  if (prop === "total_assigned_count") {
    showAssignedCoursesModal.value = !showAssignedCoursesModal.value;
    return;
  }

  showCertificatesEarnedModal.value = !showCertificatesEarnedModal.value;
};

const hideHeader = (value) => {
  if(value){
    dialogStore.zIndexValue = 0;
  }
  else{
    dialogStore.zIndexValue = 40;
  }

  dialogStore.isDialogVisible = value
}

watch(showAssignedCoursesModal,(value) => {
  hideHeader(value)
})

watch(showCertificatesEarnedModal,(value) => {
  hideHeader(value)
})

watch(showCompletedCoursesModal,(value) => {
  hideHeader(value)
})

watch(showInProgressCoursesModal,(value) => {
  hideHeader(value)
})
</script>

<style scoped></style>
