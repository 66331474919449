<template>
  <div class="bg-white px-2 sm:px-4 py-4 shadow-xl">
    <ReportSummary />

    <div class="mt-4 mb-4">
      <CompletedContent />
    </div>

    <TeamProgress v-if="hasViewTeamProgressPermission" />
    <TeamMembers v-if="hasViewTeamProgressPermission" />
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores";
import TeamMembers from "../home/TeamMembers.vue";
import ReportSummary from "./summary/ReportSummary.vue";
import TeamProgress from "./team-progress/TeamProgress.vue";
import { computed } from "vue";
import CompletedContent from "@/components/new-home/summary/CompletedContent.vue";

const authStore = useAuthStore();

const hasViewTeamProgressPermission = computed(
  () =>
    authStore.isSuperAdmin ||
    authStore.isAccountManager ||
    authStore.isDealerManager ||
    authStore.isMultiStoreManager
);
</script>

<style lang="scss" scoped></style>
