// import { featuredContents } from "@/composable/featuredContent";
import { http } from "@/composable/httpService";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { defineStore } from "pinia";
import { computed } from "vue";
import { ref } from "vue";

export const useHomeFeaturedContentStore = defineStore(
  "homeFeaturedContentStore",
  () => {
    const contents = ref();
    const fetchedNewContents = ref();

    const playlist = computed(() => contents.value?.playlists.data);
    const newContents = computed(() => fetchedNewContents.value?.new_contents);
    const loadingContents = ref(false);
    const playlistPage = ref(1);
    const perPage = ref(40);
    const isLastPage = ref(false);
    const initialLoading = ref(true);
    const initialNewContentsLoading = ref(true);
    const sortBy = ref("");

    const params = computed(() => {
      return {
        page: playlistPage.value,
        per_page: perPage.value,
        sort_by: sortBy.value,
        content_type: 'learning-paths-home',
      };
    });

    const handleFetchFeaturedContent = async () => {
      try {
        loadingContents.value = true;
        const { data } = await http().get(
          "/v3/learning-paths-and-new-contents-v2",
          {
            params: params.value,
          }
        );
        if (playlistPage.value === 1) {
          contents.value = data;
        }
        if (playlistPage.value > 1 && data.playlists.data.length) {
          contents.value = contents.value.playlist.concat(data.playlists.data);
        }

        if (contents.value) {
          //
          isLastPage.value =
            contents.value.playlists.last_page === playlistPage.value;
        }
      } catch (error) {
        useHandleErrorStatus(error);
      } finally {
        loadingContents.value = false;
        initialLoading.value = false;
      }
    };

    const handleNewContents = async () => {
      try {
        params.value.content_type = 'new-contents';

        const { data } = await http().get(
          "/v3/learning-paths-and-new-contents-v2",
          {
            params: params.value,
          }
        );

        fetchedNewContents.value = data;

      } catch (error) {
        useHandleErrorStatus(error);
      } finally {
        initialNewContentsLoading.value = false;
      }
    };

    return {
      contents,
      playlist,
      newContents,
      isLastPage,
      playlistPage,
      perPage,
      loadingContents,
      initialLoading,
      initialNewContentsLoading,
      handleFetchFeaturedContent,
      handleNewContents,
      sortBy,
    };
  }
);
