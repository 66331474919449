<template>
  <div
    class="flex flex-col justify-center items-center gap-2 w-full p-2 sm:p-4"
    v-bind="$attrs"
  >
    <p class="text-xs font-semibold text-untitled-gray-600">{{ text }}</p>
    <slot />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { defineProps } from "vue";
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
});

const text = computed(() => props.text);
</script>

<style lang="scss" scoped></style>
